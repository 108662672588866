import { useContext } from 'react';
import './styles/HomeGrid.scss'
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { useUser } from '../customHooks/userHooks';
import { homepageGame } from '../utils/gamesList';

 
const HomeGrid = () => {

    const navigate = useNavigate();

    const { setShowRegisterPopup, setClickedGameUrl, clickedGameUrl, registrationCookie } = useContext(UserContext);
    const { showRegisterBtn } = useUser();

    const handleClick = () => {
               
        setClickedGameUrl(homepageGame);
        if(showRegisterBtn) {
            setShowRegisterPopup(true); 
        } else {
            navigate(`/games/${clickedGameUrl}`);
        }

    }

    return <>
    
        <div className="gridWrapper">

            <div className="gridWrapper__grid">

            <div className="grid-col image desktop-only_1000">

                <figure>
                    <img src="/assets/images/iron-girl.webp" alt="iron girl" />
                </figure>

            </div>

            <div className="grid-col">

                <h1>Unmask the Riches: <br /> A Royal Masquerade Adventure</h1>

                <p>
                    Royal Masquerade is an enchanting slot game that transports you back to the splendor of Venetian masquerade balls. 
                    With elegant masks, opulent costumes and the promise of free spins, it's an invitation to a world of mystery and 
                    excitement. The game's beautiful design and medium to high volatility gameplay will keep you entertained while offering 
                    the chance for significant wins. Whether you're new to slots or a seasoned player, Royal Masquerade promises a delightful 
                    gaming experience that's sure to captivate you from the very first spin. Join the masked revelers and see if fortune 
                    favors the bold!
                </p>

                <div className="spacer desktop-only_1000" style={{height: '2.5em'}}></div>

                <figure className='mobile-only_1000'>
                    <img src="/assets/images/iron-girl.webp" alt="iron girl" />
                </figure>

                <div className="text-container__button">
                    
                    <button className="default-btn" onClick={handleClick}> Play Now </button>
                    
                </div>

            </div>

            </div>

        </div>

    </>

}

export default HomeGrid;