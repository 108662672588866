import { v4 as uuidv4 } from 'uuid';
import { NavLink, useLocation } from 'react-router-dom';
import './styles/Footer.scss';

const Footer = () => {


    const location = useLocation();

    const isLpPath = (location.pathname === '/lp' || location.pathname === '/lp/');
    const isTermsAgree = (location.pathname === '/terms-agree' || location.pathname === '/terms-agree/');

    const footerNavLinks = [
        {
            'link': '/privacy/',
            'title': 'Privacy Policy'
        },
        {
            'link': '/terms/',
            'title': 'Terms & Conditions'
        },
        {
            'link': '/cookies/',
            'title': 'Cookies Policy'
        },
        {
            'link': '/disclaimer/',
            'title': 'Disclaimer'
        }
    ]

    return <>
        <footer className="main-footer">

            <div className="footer-wrapper">

                <div className="footer-grid">

                    <div className="footer-col">

                        <h4>Disclaimer</h4>

                        <p>
                            Golden Kingz is a virtual gaming platform intended for entertainment purposes only. Participation in our activity 
                            does not involve real wins or prizes. All outcomes in this activity are determined by chance and are in no way 
                            influenced by prior gameplay or future results. By accessing and using our website, you guarantee that you’re above 
                            18 years old and agree that you are solely responsible for complying with any applicable laws regarding online gaming. 
                            Please use our social casino responsibly and seek assistance if you believe you may have an addiction issue.
                        </p>

                        <div className="company-data" style={{marginTop: '10px'}}>
                            Company name: Super Ads Ltd <br />
                            Address: 275 New North Road, London, N1 7AA <br />
                            Email:  <a href="mailto:contact@superadsuk.com" style={{color: '#fff'}}>contact@superadsuk.com</a> <br />
                        </div>

                        <nav>

                            {(!isTermsAgree) &&
                                <ul className=''>
                                    {
                                        footerNavLinks.map((link, index) => {
                                            const key = uuidv4();
                                            return (
                                                <li key={key}>
                                                        
                                                    <NavLink to={link.link}>{link.title}</NavLink>
                                                    
                                                    {index < footerNavLinks.length-1 &&
                                                        <span className='seperator'>|</span>
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            }

                        </nav>

                    </div>

                    <div className="footer-col">
                        <div className="footer-logo">
                            <figure className="logo">
                                {
                                    (isLpPath || isTermsAgree) ? 
                                        <img src="/assets/images/logoGK.webp" alt="logo" /> : 
                                        <NavLink to={`/`}><img src="/assets/images/logoGK.webp" alt="logo" /></NavLink>

                                }
                                
                            </figure>
                        </div>
                    </div>

                </div>

            </div>

        </footer>
    </>

}

export default Footer;