import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';

const Contact = () => {
    
    const bgImg = '/assets/images/Group.webp';

    return <div className={`contactPage`}>
        <Hero backgroundImage={bgImg}>
            <ContactForm />
        </Hero>
    </div>

}

export default Contact;