import { v4 as uuidv4 } from 'uuid';
import "./styles/GamesGrid.scss";
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { useUser } from '../customHooks/userHooks';


const GamesGrid = (props) => {
    const navigate = useNavigate();

    const { setShowRegisterPopup, setClickedGameUrl, clickedGameUrl } = useContext(UserContext);
    const { showRegisterBtn, userAuthenticated } = useUser();
    
    const backgroundImageStyle = {
        backgroundImage: `url(${props.bgImg})`,
    };

    const handleClick = (gameName) => {   
        
        setClickedGameUrl(gameName);

        if(showRegisterBtn) {
            setShowRegisterPopup(true); 
        } else {
            navigate(`/games/${gameName}`);
            
        }
    }

    return <div className="gamesWrapper"  style={props.bgImg !== undefined ? backgroundImageStyle : {}}>

            <div className="gamesWrapper__inner">
            <h1 style={{textAlign: props.tAlign}}>{props.title}</h1>

            <div className="gamesGrid">

                {
                    props.games.map((game, index) => {
                        const key = uuidv4();

                        return <div key={key} className="single-game-col">

                            <figure className='gameReg' onClick={() => handleClick(game.gameName)}>
                                <img src={game.featuredImage} alt="" />
                            </figure>

                            <button className="default-btn">Play now</button>
                            
                            
                            {props.showExcerpt &&
                                <p dangerouslySetInnerHTML={{ __html: game.excerpt}}></p>
                            }
                        </div>
                    })
                }

            </div>
        </div>

    </div>

}

export default GamesGrid;