import "./styles/Hero.scss";
import "./styles/AboutUs.scss";
import "./styles/AboutUsHome.scss";
import "./styles/ContactPage.scss";
import './styles/LpKnight.scss'

function Hero({
    backgroundImage = null, 
    topLayerImage = null, 
    children
 }) {

    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImage})`,
    };

    return <>
        <div className="bg-container" style={backgroundImageStyle}>

            <div className="wrapper">

                <div className="bg__text-container">

                    {children}

                </div>

            </div>

            {topLayerImage &&
                <figure className="top-layer__img">
                    <img src={topLayerImage} alt="princess" />
                </figure>
            }

        </div>
    </>

}

export default Hero;