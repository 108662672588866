import {useState, useEffect, useContext} from 'react';
import {useLocation} from "react-router-dom";
import { UserContext } from '../context/UserContext';
import checkUserAuth from '../utils/userData';


export const useUser = () => {
  const { pathname } = useLocation();
  const { cookies, showRegisterPopup, setShowRegisterPopup, showRegisterBtn, setShowRegisterBtn } = useContext(UserContext);

  // const [showRegisterBtn, setShowRegisterBtn] = useState(true);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  useEffect(() => {

    checkUserAuth()
      .then((result) => {
        // console.log(result);
        if (result.is_auth === true) {
          setShowRegisterBtn(false);
          setShowRegisterPopup(false);
          setUserAuthenticated(true);
        }
        // else if(result.is_auth === false) {
        //   if(pathname.split('/')[1] === "games"){
        //     setTimeout(() => {
        //       if(cookies.is18 === 'false') {
        //         setShowRegisterPopup(false);
        //       } else {
        //         setShowRegisterPopup(true);
        //       }
        //     }, 30000)
        //   }
        // }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [cookies.is18, pathname]);

  return {
    showRegisterBtn,
    setShowRegisterBtn,
    showRegisterPopup,
    setShowRegisterPopup,
    userAuthenticated,
    cookies
  };
};
