import { useState } from 'react';
import './styles/ContactForm.scss';

const ContactForm = () => {

    const [nameError, setNameError] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [messageError, setMessageError] = useState(true);
  
    const [nameVal, setNameVal] = useState();
    const [emailVal, setEmailVal] = useState();
    const [messageVal, setMessageVal] = useState();
  
    const [showForm, setShowForm] = useState(true);
  
    const nameRegex = /^[a-zA-Z ]+$/; //eslint-disable-line
    const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; //eslint-disable-line

    const validateForm = (e) => {
        e.preventDefault();
    
        checkName();
        checkEmail();
        checkMessage();
    
        if (!nameError &&
          !emailError &&
          !messageError) {
          setShowForm(false);
          resetForm();
        }
    };
    
    const checkName = () => {
        const name = document.getElementById('name-c').value;
        const errorMsg = document.getElementById('name_error');
    
        if (!nameRegex.test(name) || name === '' || name.length < 2) {
          errorMsg.textContent = `Enter your name`;
          setNameError(true);
          setNameVal('');
        } else {
          setNameError(false);
          setNameVal(name);
        }
    };

    const checkEmail = () => {
        const email = document.getElementById('email-c').value;
        const errorMsg = document.getElementById('email_error');
    
        const validateEmail = (val) => {
          return emailRegex.test(val);
        };
    
        if (email === '') {
          errorMsg.textContent = `Enter email`;
          setEmailError(true);
          setEmailVal('');
        } else if (!validateEmail(email)) {
          errorMsg.textContent = `Enter correct email`;
          setEmailError(true);
          setEmailVal('');
        } else {
          setEmailError(false);
          setEmailVal(email);
        }
    };
    
    const checkMessage = () => {
        const message = document.getElementById('message').value;
        const errorMsg = document.getElementById('message_error');
    
        if (message.length < 5) {
          errorMsg.textContent = `Enter your message`;
          setMessageError(true);
          setMessageVal('');
        } else {
          setMessageError(false);
          setMessageVal(message);
        }
    };
    
    const resetForm = () => {
        setNameError(false);
        setEmailError(false);
        setMessageError(false);
    
        document.getElementById('name-c').value = '';
        document.getElementById('email-c').value = '';
        document.getElementById('message').value = '';
    };

    return (
        <div className='ContactForm'>
          <h1 className={`title ${showForm ? '' : 'hide-form'}`}>Contact Us</h1>
          <div className="spacer" style={{height: '0.5em'}}></div>
          <form action="#" method="post" className={showForm ? '' : 'hide-form'} >
            <label htmlFor="name-c">
              <input onChange={checkName} type="text" id="name-c" placeholder="Enter your full name" />
              <div className={nameError ? 'error_message-show' : 'error_message-hide'} id="name_error" ></div>
            </label>
    
            <label htmlFor="email-c">
              <input onChange={checkEmail} type="email" id="email-c" placeholder="Enter a valid email address" />
              <div className={emailError ? 'error_message-show' : 'error_message-hide'} id="email_error" ></div>
            </label>
    
            <label htmlFor="message">
              <textarea onChange={checkMessage} name="message" id="message" cols="20" rows="5" placeholder="Type your message here"></textarea>
              <div className={messageError ? 'error_message-show' : 'error_message-hide'} id="message_error" ></div>
            </label>
    
            <button className='default-btn' type="submit" onClick={validateForm}>Submit</button>
          </form>
          <div className={showForm ? 'hide-success-message' : 'show-success-message'}>Thank you for your message! We will contact you soon</div>
        </div>
    );

}

export default ContactForm;