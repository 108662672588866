import GamesGrid from "../components/GamesGrid";
import LpGrid from "../components/LpGrid";
import LpKnight from "../components/LpKnight";
import { gamesList, lpGameLinkArr } from "../utils/gamesList";
import '../components/styles/LpPage.scss'
import LpHero from "../components/LpHero";



const Lp = () => {

    const filteredGameLinks = lpGameLinkArr.map(index => gamesList[index]);

    

    return <div className="LpPage">
        <LpHero />
        <LpGrid />
        <GamesGrid showExcerpt={false} title={`Start an adventure in our Kingdom of Games`} tAlign={'left'} games={filteredGameLinks} />
        <div className="spacer" style={{height: '6.888888888888889em'}}></div>
        <LpKnight />
        
    </div>

}

export default Lp;