import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import './components/styles/index.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import About from './pages/About';
import Top5 from './pages/Top5';
import AllGames from './pages/AllGames';
import PageNotFound from './pages/PageNotFound';
import Terms from './pages/legal/Terms';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import Disclaimer from './pages/legal/Disclaimer';
import CookiesPolicy from './pages/legal/CookiesPolicy';
import Contact from './pages/Contact';
import { UserProvider } from './context/UserContext';
// import LandingPage from './pages/Lp';
import Play from './components/Play';
import Lp from './pages/Lp';


function App() {

  const pages = [
    { path: '/lp/', element: <Lp /> },
    { path: '/', element: <Home />, exact: true },
    { path: '/home/', element: <Home /> },
    { path: '/about/', element: <About /> },
    { path: '/top5/', element: <Top5 /> },
    { path: '/all-games/', element: <AllGames /> },
    { path: '*', element: <PageNotFound /> },
    { path: '/terms/', element: <Terms agree={false} /> },
    { path: '/terms-agree/', element: <Terms agree={true} /> },
    { path: '/privacy/', element: <PrivacyPolicy /> },
    { path: '/disclaimer/', element: <Disclaimer /> },
    { path: '/cookies/', element: <CookiesPolicy /> },

    { path: '/contact/', element: <Contact /> },
    { path: '/games/:gameUrl', element: <Play /> },
  ];



  return (
    <Router>
      <UserProvider>
        <div className="App">
          <Header />
          <Routes>
            {pages.map((route) => (
                <Route
                key={route.path}
                path={route.path}
                element={route.element}
                exact={route.exact}
                />
            ))}
          </Routes>
          <Footer />
        </div>
      </UserProvider>
    </Router>
  );
}

export default App;
