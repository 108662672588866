import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './styles/Header.scss';
import AgePopUp from './AgePopUp';
import ThankYouPopUp from './ThankYouPopUp';
import PopupContainer from './PopupContainer';
import LogIn from './LogIn';
import SignUp from './SignUp';
import { UserContext } from '../context/UserContext';
import CookiesPopup from './CookiesPopup';
import { useUser } from '../customHooks/userHooks';
import SendThriveRequest from "./SendThriveRequest";

function Header() {

    const location = useLocation();

    const isLpPath = (location.pathname === '/lp' || location.pathname === '/lp/');
    const isTermsAgree = (location.pathname === '/terms-agree' || location.pathname === '/terms-agree/');

    const [mobMenu, setMobMenu] = useState(false);
    const [isCookie, setIsCookie] = useState(false);
    const [is404, setIs404] = useState(false);
    const { showRegisterPopup, setShowRegisterPopup, 
            showLoginPopup, setShowLoginPopup, 
            showThanksPopup, 
            setShowAgepopup, showAgePopup,
            cookies,
            showCookiePopup, setShowCookiePopup,
            userLogout, showRegisterBtn
        } = useContext(UserContext);

    const { pathname } = useLocation();
    // const { showRegisterBtn } = useUser();
  
    // const [hidePopup, setHidePopup] = useState(false);
    // const [showLoginPopup, setShowLoginPopup] = useState(false);

    const toggleMobMenu = () => {
        setMobMenu(!mobMenu);
    };

    const navLinks = [
        {
            'link': '/',
            'title': 'Welcome'
        },
        {
            'link': '/about/',
            'title': 'About Us'
        },
        {
            'link': '/contact/',
            'title': 'Contacts'
        }
    ]


    useEffect(() => {
        if (cookies.is18 === 'false' && pathname === '/cookies/') {
          setShowAgepopup(false);
          setIsCookie(true);
        } else if (cookies.is18 === 'true') {
          setShowAgepopup(false);
          setIsCookie(false);
        } else {
          setShowAgepopup(true);
          setIsCookie(false);
        }


        if (pathname === '/page404/' || pathname === '/page404') {
          setIs404(true);
        } else {
          setIs404(false);
        }
    
    }, [cookies.is18, pathname]);

    useEffect(() => {
        setMobMenu(false);
    }, [pathname])


  return (
    <div>

      <SendThriveRequest />
      
      <header className="header-section">

        <div className="header-section-disclaimer">
            The Free Game Palace: Everything’s free
        </div>

        <div className='main-nav desktop-nav'>

            <nav className={`header-section-cover`}>
            
                {!(isLpPath || isTermsAgree) &&
                    <ul className=''>

                        {
                            navLinks.map(link => {
                                const key = uuidv4();
                                return (
                                    <li key={key}>
                                        <NavLink to={link.link}>{link.title}</NavLink>
                                    </li>
                                )
                            })
                        }

                    </ul>
                }

            </nav>

            <figure className="logo">
                {
                    (isLpPath || isTermsAgree) ? 
                        <img src="/assets/images/logoGK.webp" alt="logo" /> : 
                        <Link to={`/`}><img src="/assets/images/logoGK.webp" alt="logo" /></Link>
                }
            </figure>

            
            <div className="header-btns">
                {(!isTermsAgree) &&
                    <>
                    {showRegisterBtn === true &&
                        <>
                            <button className="register-btn" 
                                onClick={() => {
                                    setShowRegisterPopup(true);
                                }}>
                                    <span>Sign Up</span>
                            </button>
                            <button className="login-btn" 
                                onClick={() => {
                                    setShowLoginPopup(true);
                                }}>
                                <span>Log In</span>
                            </button>
                        </>
                    }
                    {showRegisterBtn === false &&
                        <button className="logout-btn" onClick={userLogout}>
                            <span>Log Out</span>
                        </button>
                    }
                    </>
                }
            </div>
            

        </div>

        <div className='main-nav mobile-nav'>

            <figure className="logo">
                <Link to='/'><img src="/assets/images/logoGK.webp" alt="logo" /></Link>
            </figure>

            {!(isTermsAgree) &&
                <div className={`mobile-menu-btn ${mobMenu ? `opened` : ``}`} onClick={toggleMobMenu}>
                    <div></div>
                </div>
            }

            <nav className={`${mobMenu ? `opened` : ``}`}>
            
                
                <ul className=''>

                    {!isLpPath &&
                    <>
                        {
                            navLinks.map(link => {
                                const key = uuidv4();
                                return (
                                    <li key={key}>
                                        <NavLink to={link.link}>{link.title}</NavLink>
                                    </li>
                                )
                            })
                        }
                    </>}

                    <div className="header-btns">

                    {showRegisterBtn === true &&
                        <>
                            <button className="register-btn" 
                                onClick={() => {
                                    setShowRegisterPopup(true);
                                }}>
                                <span>Sign Up</span>
                            </button>
                            <button className="login-btn mobile-menu-login-btn" 
                                onClick={() => {
                                    setShowLoginPopup(true);
                                }}>
                                <span>Log In</span>
                            </button>
                        </>
                    }

                    {showRegisterBtn === false &&
                        <button className="logout-btn" onClick={userLogout} >
                            <span>Log Out</span>
                        </button>
                    }

                    </div>

                </ul>

            </nav>

        </div>

      </header>

      {/* AgePopUp */}
      
      {
        (showAgePopup && !cookies.is18 && !isTermsAgree) &&
        <PopupContainer showCloseBtn={false} centered={true} >
            
            <AgePopUp />
        </PopupContainer>
      }
      
      
      {/* Thank You Popup */}
        {
            showThanksPopup &&
            <PopupContainer showCloseBtn={true} centered={true}>
                <ThankYouPopUp />
            </PopupContainer>
        }

        {/* LogIn Popup */}
        {
            showLoginPopup &&
                <PopupContainer showCloseBtn={true} centered={true} >
                    <LogIn />
                </PopupContainer>
        }
                

        {/* SignUp Popup */}
        {
            showRegisterPopup &&
                <PopupContainer showCloseBtn={true} centered={true}>
                    <SignUp />
                </PopupContainer>
        }

        {/* Cookies Popup */}
        {
            showCookiePopup &&
            <PopupContainer showCloseBtn={true} centered={false}>
                <CookiesPopup />
            </PopupContainer>
        }
        
        
      

    </div>
  );
}

export default Header;
