import { useContext, useState } from 'react';
import './styles/Login.scss';
import { UserContext } from '../context/UserContext';
import { registerUser } from '../utils/dataApi';
import { useNavigate } from 'react-router'

const LogIn = () => {
    const { setShowRegisterPopup, setShowLoginPopup, setShowRegisterBtn, setUAuth } = useContext(UserContext);
    const [errors, setErrors] = useState({});
    const [formDataState, setFormData] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();

    
    const handleChange = (e) => {

        const { name, value } = e.target;
  
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const validateEmail = (val) => {
        return emailRegex.test(val);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};



        if (!formDataState.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!validateEmail(formDataState.email)) {
            newErrors.email = 'Invalid email address';
        }

        if (!formDataState.password.trim()) {
            newErrors.password = 'Password is required';
        } else if (formDataState.password.length < 8) {
            newErrors.password = 'Minimum password width is 8 characters';
        }

        if (Object.keys(newErrors).length === 0) {
            
            // const options = {
            //     method: 'POST',
            //     body: new URLSearchParams({
            //       user: JSON.stringify({
            //         email: formData.email,
            //         password: formData.password,
            //       })
            //     })
            // };

            const formData = new FormData();
            formData.append('user[email]', formDataState.email);
            formData.append('user[password]', formDataState.password);

            const url = registerUser('login');

            const options = {
                method: 'POST',
                body: formData,
            };


            fetch(url, options)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 'success') {
                
                    setShowLoginPopup(false);
                    setShowRegisterBtn(false);

                    setUAuth(true);

                    navigate(0);

                } else {

                    if (result.message === 'Password is incorrect') {
                        newErrors.password = result.message;
                    } else {
                        newErrors.email = result.message;
                    }

                    setUAuth(false);

                    setErrors(newErrors);

                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
            
        } else {
            setErrors(newErrors);
        }
    
    };

    return (
        <div className="login">
        
            <h2>LOG IN</h2>

            <form onSubmit={handleSubmit}>

                <div>
                    <input type="email" name='email' id="email" placeholder="Your email" onChange={handleChange} value={formDataState.email} />
                    {errors.email && <span className="error">{errors.email}</span>}
                </div>

                <div>
                    <input type="password" name='password' id="password" placeholder="Password" onChange={handleChange} value={formDataState.password} />
                    {errors.password && <span className="error">{errors.password}</span>}
                </div>

                <div className="spacer" style={{height: '1.8em'}}></div>

                <button type="submit" className='default-btn' id="login-btn" >Log In</button>

                <div className="spacer" style={{height: '1.5em'}}></div>

                <div className='changePopup'>
                    Don’t have an account?&nbsp; <span style={{cursor: 'pointer'}} onClick={() => { setShowLoginPopup(false); setShowRegisterPopup(true); }}>Sign up</span>
                </div>

            </form>

        </div>
    );

}

export default LogIn;