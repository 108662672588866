import { UserContext } from '../context/UserContext';
import './styles/Login.scss';
import React, { useState, useContext } from 'react';
import { registerUser } from '../utils/dataApi';
import IntlTelInput from 'react-intl-tel-input';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './Loader';

import 'react-intl-tel-input/dist/main.css';
import { homepageGame } from '../utils/gamesList';


const SignUp = () => {
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { 
      setShowRegisterPopup, setShowThanksPopup,
      setShowRegisterBtn, 
      setShowLoginPopup, countryData, registrationCookie,
      clickedGameUrl, setUAuth
    } = useContext(UserContext);

    const [formDataState, setFormData] = useState({
        fullName: '',
        phone: '',
        email: '',
        password: '',
        agreeToTerms: false,
        countryCode: '',
        country: ''
    });
    let [errorPhone, setErrorPhone] = useState(true);
     
    const [errors, setErrors] = useState({});
  
    const handleChange = (e) => {

      const { name, value, type, checked } = e.target;

      if (type === 'checkbox') { 
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    }
    
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const phoneRegex = /^\+[1-9]{1}[0-9]{1,14}(\s?[0-9]{1,4}){0,3}$/;

    const validateEmail = (val) => {
      return emailRegex.test(val);
    };

    // const validatePhone = (val) => {
    //   return phoneRegex.test(val);
    // };

    // console.log(errorPhone);
    
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Basic validation
      const newErrors = {};
      const fn = formDataState.fullName.trim();

      const nameRegex = /^[a-zA-Z][a-zA-Z'\-]*[a-zA-Z] [a-zA-Z][a-zA-Z'\-]*[a-zA-Z]$/;  //eslint-disable-line
      if (!nameRegex.test(fn) || fn === '') {
          newErrors.fullName = 'Full Name is required';
      }

      if(errorPhone) {
        newErrors.phone = 'Invalid phone number';
      }

      if (!formDataState.email.trim()) {
        newErrors.email = 'Email is required';
      } else if (!validateEmail(formDataState.email)) {
        newErrors.email = 'Invalid email address';
      }

      if (!formDataState.password.trim()) {
        newErrors.password = 'Password is required';
      } else if (formDataState.password.length < 6) {
        newErrors.password = 'Minimum password width is 6 characters';
      }

      if (!formDataState.agreeToTerms) {
        newErrors.agreeToTerms = 'You must agree to Terms & Conditions';
      }
  
      if (Object.keys(newErrors).length === 0) {

        setIsSubmitting(true);

        const url = registerUser('register');

        const formData = new FormData();
        formData.append('user[email]', formDataState.email);
        formData.append('user[password]', formDataState.password);
        formData.append('user[user_name]', formDataState.fullName);
        formData.append('user[phone_number]', formDataState.phone.replace(/[)( -]/g,''));
        formData.append('user[country]', formDataState.country);
        formData.append('user[country_code]', formDataState.countryCode);

        const options = {
          method: 'POST',
          body: formData,
        };

        fetch(url, options)
          .then((response) => response.json())
          .then((result) => {
            if (result.status === 'success') {

              console.log('user registered')
              
              setShowRegisterPopup(false);
              setShowThanksPopup(true);
              setShowRegisterBtn(false);
              setUAuth(true);
              
              if(clickedGameUrl !== homepageGame) {
                navigate(`/games/${clickedGameUrl}`);
              }

              // navigate(0);

            } else {

              if (result.message === 'Password is incorrect') {
                newErrors.password = result.message;
              } else {
                newErrors.email = result.message;
              }
              setUAuth(false);

              setErrors(newErrors);

            }

          })
          .catch((error) => {
            console.error('Error:', error);
          }).finally(() => {
            setIsSubmitting(false); // Enable the submit button
        });

      } else {
        setErrors(newErrors);
      }
    };
    

    return (
      <>
          { isSubmitting && <Loader /> }
          <div className="login">

            <h2>WELCOME</h2>

            <form onSubmit={handleSubmit}>

                <div className='formField'>
                    <input
                        type="text"
                        id="fullName"
                        name="fullName"
                        value={formDataState.fullName}
                        onChange={handleChange}
                        placeholder='Full name'
                    />
                    {errors.fullName && <p className="error popupError">{errors.fullName}</p>}
                </div>

                <div className='formField'>
                    <IntlTelInput
                      preferredCountries={[countryData.code]}
                      fieldId={'phone-number'}

                      onPhoneNumberChange={(isValid, a, b, number) => {
                        let name = b.name.split('(')[0];
                        name = name.trim();
                        let countryCode = b.iso2.toString();
                        setErrorPhone(false);
                        if (isValid) {

                          formDataState.phone = number;
                          formDataState.country = name;
                          formDataState.countryCode = countryCode;
                          setErrorPhone(false);
                        } else {
                          setErrorPhone(true);
                        }
                      }}

                      onPhoneNumberBlur={(isValid) => {
                        setErrorPhone(!isValid);
                      }}
                    />
                    {errors.phone && <p className="error popupError">{errors.phone}</p>}
                </div>

                <div className='formField'>

                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formDataState.email}
                        onChange={handleChange}
                        placeholder='Your email'
                    />
                    {errors.email && <p className="error popupError">{errors.email}</p>}
                </div>

                <div className='formField'>

                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formDataState.password}
                        onChange={handleChange}
                        placeholder='Password'
                    />
                    {errors.password && <p className="error popupError">{errors.password}</p>}
                </div>

                <div className="spacer" style={{height: '1.6em'}}></div>

                <div className="checkbox-cover checkbox2">
                  <input type="checkbox" name="agreeToTerms" id="agreeToTerms" checked={formDataState.agreeToTerms} onChange={handleChange} />
                  <label htmlFor="agreeToTerms">

                      I agree to Terms & Conditions and Privacy Policy.
                      <span className="box checkbox"></span>
                  </label>
                  {errors.agreeToTerms && (
                      <p className="error popupError">{errors.agreeToTerms}</p>
                    )}
                </div>

                <div className="checkbox-cover checkbox2">
                  <input type="checkbox" name="receivePromotions" id="receivePromotions" />
                  <label htmlFor="receivePromotions">

                      I would like to receive exclusive promotions via Email & SMS.
                      <span className="box checkbox"></span>
                  </label>
                </div>

                <div className="spacer" style={{height: '2em'}}></div>

                <div className={`${registrationCookie !== 'force' ? `skip-play__grid` : ``}`}>
                  <button type="submit" className='default-btn' id="register" disabled={isSubmitting} >
                    {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                  </button>
                  {registrationCookie !== 'force' &&
                    <>
                      <span className='f-overlock'>or</span>
                      <Link to={`/games/${clickedGameUrl}/`} className='default-btn red' onClick={() => {setShowRegisterPopup(false)}}> Skip&Play </Link></>
                  }
                </div>

                <div className="spacer" style={{height: '1.5em'}}></div>

                <div className='changePopup'>
                    Do you already have an account? &nbsp;
                    <span style={{cursor: 'pointer'}} onClick={() => { setShowLoginPopup(true); setShowRegisterPopup(false); }}>Log in</span>
                </div>

            </form>
        </div>

      </>
    );

}

export default SignUp;