import "./styles/LpHero.scss";
import { homepageGame, lpGame } from '../utils/gamesList';
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../customHooks/userHooks';

const LpHero = () => {

    const { setShowRegisterPopup, setClickedGameUrl, clickedGameUrl } = useContext(UserContext);
    const { showRegisterBtn } = useUser();
    const navigate = useNavigate();

    const handleClick = () => {
               
        setClickedGameUrl(lpGame);
        if(showRegisterBtn) {
            setShowRegisterPopup(true); 
        } else {
            navigate(`/games/${lpGame}`);
        }

    }

    const backgroundImageStyle = {
        backgroundImage: `url(/assets/images/dining-room-castleLP.webp)`,
    };

    return <>
        <div className="lp-bg-container" style={backgroundImageStyle}>

            <div className="lp-wrapper">

                <div className="spacer" style={{height: '9.388888888888889em'}}></div>

                <div className="lp-boxes-grid">

                    <div className="box">
                        <h2>Register</h2>
                        <p>Join our esteemed community of noble players and unlock a world of excitement and rewards.</p>
                        <div className="number">
                            1
                        </div>
                    </div>
                    <div className="box">
                        <h2>Discover games</h2>
                        <p>Uncover hidden treasures, engage in strategic challenges, and experience the thrill of the wager in our enchanting virtual palace.</p>
                        <div className="number">
                            2
                        </div>
                    </div>
                    <div className="box">
                        <h2>Enjoy playing</h2>
                        <p>Embrace the excitement and indulge in the joy of playing at the GoldenKingz in a world of opulence and grandeur.</p>
                        <div className="number">
                            3
                        </div>
                    </div>

                </div>

                <div className="spacer" style={{height: '4em'}}></div>

                <div className="text-container__button">
                    <button className="default-btn" onClick={handleClick}> Play Now </button>
                </div>

                <div className="spacer" style={{height: '8em'}}></div>

            </div>

        </div>
    </>
}

export default LpHero;