import React, { useEffect, useContext } from 'react';

import Hero from '../components/Hero';
import HomeGrid from '../components/HomeGrid';
import AboutUsHome from '../components/AboutUsHome';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { useUser } from '../customHooks/userHooks';
import { homepageGame } from '../utils/gamesList';

const Home =  () => {

    const navigate = useNavigate();

    const { setShowRegisterPopup, setClickedGameUrl, clickedGameUrl } = useContext(UserContext);
    const { showRegisterBtn } = useUser();

    const handleClick = () => {
               
        setClickedGameUrl(homepageGame);
        if(showRegisterBtn) {
            setShowRegisterPopup(true); 
        } else {
            navigate(`/games/${clickedGameUrl}`);
        }

    }

    useEffect(() => {
        document.title = 'Goldenkingz - Home';
    }, []);

    return <div className='homepage'>
        <Hero backgroundImage='/assets/images/throne1.webp' topLayerImage='/assets/images/princess1.webp'>
            <h1>Welcome To The Game Kingdom</h1>

            <p>
                Welcome To The Game Kingdom <br />
                Step into Golden Kingz, a realm of kings and queens, where the noblest of pursuits—entertainment and fortune—converge 
                in perfect harmony and absolutely free! As you enter our virtual palace, you'll be greeted by a mesmerizing Social Casino 
                atmosphere. Here, you’ll find the most exciting game that we’ve prepared for you. Oh, and don’t forget to create your 
                account to start with. 
            </p>

            <div className="spacer" style={{height: '2.5em'}}></div>

            <div className="text-container__button">
                <button className="default-btn" onClick={handleClick}> Play Now </button>
            </div>
            
        </Hero>
        <HomeGrid />
        <AboutUsHome />
    </div>

}

export default Home;