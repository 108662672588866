import { useEffect } from "react";
import GamesGrid from "../components/GamesGrid";
import { gamesList, top5GameLinkArr } from "../utils/gamesList";

const Top5 = () => {

    useEffect(() => {
        document.title = 'Goldenkingz - Top 5';
    }, []);

    const filteredGameLinks = top5GameLinkArr.map(index => gamesList[index]);

    return <>
        <GamesGrid bgImg='/assets/images/top-5.webp' showExcerpt={true} title={`Navigate through our game paradise`} tAlign={'center'} games={filteredGameLinks} />
        
    </>

}

export default Top5;