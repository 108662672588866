
import './styles/PopupContainer.scss';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const AgePopUp = () => {

    const [isAgeTerms, setIsAgeTerms] = useState(false);
    const [initiallyHidden, setInitiallyHidden] = useState(true);
    const [blockContent, setBlockContent] = useState(false);
    const { setCookie, setShowAgepopup } = useContext(UserContext);

    const checkAgeTerms = () => {
      setIsAgeTerms(!isAgeTerms);
    };

    

    const hideAgePopup = (e) => {
      
      if(! isAgeTerms) {
        setInitiallyHidden(false)
      }
  
      if (isAgeTerms) {
        setCookie('is18', true, { path: '/' });
        setShowAgepopup(false);
      }
    };

    const closeAgePopup = () => {
      setBlockContent(true);
    };

  

    return (
      <>
      {!blockContent ?
          (<><h2>We just need to <br />check your age</h2>
          <h3>Are you over 18 years old?</h3>
          
          <div className="checkbox-cover">
            <input type="checkbox" name="terms-18" id="terms-18" onChange={checkAgeTerms} />
            <label htmlFor="terms-18">
            
                I agree to <Link to="/terms-agree/" /*onClick={openCookiePage}*/>Terms & Conditions</Link>
                <span className="box checkbox"></span>
            </label>

            {(!isAgeTerms && !initiallyHidden) &&
              <div className='initiallyHidden popupError'>This checkbox is required</div>
            }
          </div>

          <div className="buttons">
            <button className='default-btn' id="button-yes" onClick={hideAgePopup} >Yes</button>
            <button className='default-btn red' id="button-no" onClick={closeAgePopup} >No</button>
          </div></>) : (
            <>
              <div className="spacer" style={{height: '2.5em'}}></div>
              <h2>Sorry, we can’t let you <br />play our games <br /> :(</h2>
              <div className="spacer" style={{height: '2.5em'}}></div>
            </>
          )
        }
      </>
    );

}

export default AgePopUp;