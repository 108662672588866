
import { Link } from "react-router-dom";
import './styles/CookiesPopup.scss'
import { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";

const CookiesPopup = () => {

    const { showCookiePopup, setShowCookiePopup  } = useContext(UserContext);

    useEffect(() => {
        const hasAcceptedCookies = localStorage.getItem('acceptedCookies');
    
        if (hasAcceptedCookies) {
            setShowCookiePopup(false);
        }
    }, []);

    const handleClick = () => {
        localStorage.setItem('acceptedCookies', 'true');
        setShowCookiePopup(false)
    }

    return <div className="PopupCookie">

        <h4>We Use Cookies</h4>
    
        <p>Our website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.</p>

        <button className='default-btn' id="button-yes" onClick={handleClick}>Yes, I like cookies</button>
        <br />
        <Link target="_blank" to="/cookies/" className="cookiesLink" /*onClick={openCookiePage}*/>Cookies Policy</Link>

    </div>

}

export default CookiesPopup;