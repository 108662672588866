// export const top10GameLinkArr = [0, 1, 2, 7, 8, 3, 10, 6, 5, 9];
export const lpGameLinkArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const top5GameLinkArr = [0, 7, 2, 9, 5];
export const homepageGame = 'royal-masquerade';
export const lpGame = 'gold-king';

export const gamesList = [
    {
        gameName: 'royal-masquerade',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=masquerade&gameId=298&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
        featuredImage: '/assets/images/iron-girl.webp',
        excerpt: `Immerse yourself in the splendor of a lavish party at a masked ball, where mystery and vibrancy abound, creating an unexpected adventure in an elegant setting.`
    },
    {
        gameName: 'rabbit-hole-riches',
        url: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=rabbitholeriches&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
        featuredImage: '/assets/images/coils-cash.webp',
        excerpt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla massa elit, ut eleifend nunc auctor at. `
    },
    {
        gameName: 'golden-legend',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=goldenlegend&gameId=290&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
        featuredImage: '/assets/images/coils-cash-1.webp',
        excerpt: `Transport into an Asian mythological world rich with golden symbols, mythical creatures, and auspicious elements, offering a chance to uncover treasures and fortunes. `
    },
    {
        gameName: 'prosperity-palace',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=prosperitypalace&gameId=328&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
        featuredImage: '/assets/images/energoonz.webp',
        excerpt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla massa elit, ut eleifend nunc auctor at. `
    },
    {
        gameName: 'hugo-carts',
        url: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=hugocarts&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
        featuredImage: '/assets/images/hugo-carts.webp',
        excerpt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla massa elit, ut eleifend nunc auctor at. `
    },
    {
        gameName: 'aztec-idols',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=aztecidols&gameId=243&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
        featuredImage: '/assets/images/space-race.webp',
        excerpt: `Join the daring explorer in an exhilarating journey deep into the heart of the Aztec civilization, uncovering bonuses and valuable artifacts amidst temples and idols.`
    },
    {
        gameName: 'big-win-cat',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bigwincat&gameId=342&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
        featuredImage: '/assets/images/space-race-1.webp',
        excerpt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla massa elit, ut eleifend nunc auctor at. `
    },
    {
        gameName: 'battle-royal',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=battleroyal&gameId=356&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
        featuredImage: '/assets/images/space-race-2.webp',
        excerpt: `Engage in a regal struggle within a realm of intense royal battles, seeking victory amidst bonuses and unforeseen challenges in an adventurous narrative.`
    },
    {
        gameName: 'banana-rock',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bananarock&gameId=347&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime= ',
        featuredImage: '/assets/images/star-bounty.webp',
        excerpt: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla massa elit, ut eleifend nunc auctor at. `
    },
    {
        gameName: 'prissy-princess',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=prissyprincess&gameId=325&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
        featuredImage: '/assets/images/star-bounty-1.webp',
        excerpt: `Enter a whimsical medieval universe filled with castles, knights, and playful animations, embarking on a lighthearted quest for riches and entertainment.`
    },
    {
        gameName: 'gold-king',
        url: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=goldking&gameId=341&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
        featuredImage: '/assets/images/image-1.webp',
        excerpt: `This captivating game invites players to enter a kingdom in search of untold treasures. With its luxurious design and enticing features, "Gold King" promises a gaming experience fit for royalty. The intricate details and high-quality graphics create a visually stunning backdrop that enhances the overall gameplay.`
    },
]