
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from 'react-router-dom';

const PopupContainer = ({showCloseBtn, centered, children}) => {
    const navigate = useNavigate();

    const { setShowRegisterPopup, setShowLoginPopup, setShowThanksPopup, setShowCookiePopup  } = useContext(UserContext);

    const handleClosePopup = () => {
        setShowRegisterPopup(false);
        setShowLoginPopup(false);
        setShowThanksPopup(false);
        setShowCookiePopup(false);

        navigate(0);
    }

    return <div className={`popupContainer ${centered ? 'centered' : ''}`}>
        <div className="popup_inner">
            {
                showCloseBtn &&
                <button className="closeBtn" onClick={handleClosePopup}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                        <rect x="8.31885" y="25.9967" width="25" height="2.20588" transform="rotate(-45 8.31885 25.9967)" fill="white"/>
                        <rect x="25.9966" y="27.0365" width="25" height="2.20588" transform="rotate(-135 25.9966 27.0365)" fill="white"/>
                    </svg>
                </button>
            }
            <div className="poupContent">
                {children}
            </div>
        </div>
    </div>

}

export default PopupContainer;