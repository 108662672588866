import {Circles} from "react-loader-spinner";
import React from "react";


const Loader = () => {
    return   <>
                <div className="overlay" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.7)', backdropFilter: 'blur(1px)', zIndex: 999, display: 'flex',
                    alignItems: 'center', justifyContent: 'center' }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </>
}
export default Loader;