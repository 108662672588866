import { useEffect } from "react";
import GamesGrid from "../components/GamesGrid";
import { gamesList } from "../utils/gamesList";

const AllGames = () => {

    useEffect(() => {
        document.title = 'Goldenkingz - All Games';
    }, []);

    return <>
        <GamesGrid bgImg='/assets/images/top-5.webp' showExcerpt={false} title={`Start an adventure in our Kingdom of Games`} tAlign={'center'} games={gamesList} />
        
    </>

}

export default AllGames;