import Hero from "./Hero";
import "./styles/Hero.scss"

const AboutUs = () => {
    const bgImg = '/assets/images/castle-steps.webp';
    const tLImg = '/assets/images/princes2.webp';

    return <div className={`AboutUs`}>
        
        <Hero backgroundImage={bgImg} topLayerImage={tLImg} >
            <h1>A Message from the King</h1>

            <p>
                Welcome, noble guests, to our distinguished realm of Golden Kingz. In this hallowed space, we have endeavored 
                to create an online sanctuary where the thrill of gaming meets the grandeur of royal courts. As you immerse yourself 
                in our virtual palace, you will be enchanted by the regal atmosphere that permeates every corner. The spirit of chivalry, 
                honor, and prosperity reigns supreme.
            </p>
            <p>
                Our vision is to provide you, our esteemed guests, with an unparalleled, free and safe gaming experience that befits 
                your noble stature. The realm of Golden Kingz is adorned with the most interesting free-to-play casino options. Our team 
                is dedicated to searching for new opportunities for you. Every moment spent here is destined to leave you breathless with 
                anticipation.
            </p>
            <p>
                And everything is composed in the safest way possible: we don’t charge you for our content. We know you won’t receive real 
                prizes here, but you’ll never lose anything - and that matters! For Golden Kingz, you can use whatever mobile device you have 
                or a computer.
            </p>

            <p>
                Join us, noble guests, and let the grandeur of Golden Kingz unfold before your very eyes. May your time here be marked by 
                resounding triumphs and memories fit for the annals of legends.
            </p>

            <div className="spacer" style={{height: '2.5em'}}></div>

        </Hero>

    </div>
}

export default AboutUs