import React from 'react';
import Cookies from 'js-cookie';

function useSecretCookies() {

    const defaultParams = () => {
        const domain = window.location.hostname.replace(/^(www.)/, '');
        return { expires: 365, path: '/', domain: domain };
    };

    const getParsedSecretCookies = (secretKey) => {
        const encodedCookies = Cookies.get(secretKey);
        if (encodedCookies === undefined) {
            return {};
        }
        return JSON.parse(atob(encodedCookies));
    };

    const setSecretCookies = (secretKey, updatedCookies, params = null) => {
        const currentCookies = getParsedSecretCookies(secretKey);
        const updatedCookiesObj = { ...currentCookies, ...updatedCookies };

        const cookieParams = params || defaultParams();
        Cookies.set(secretKey, btoa(JSON.stringify(updatedCookiesObj)), cookieParams);
    };

    const getSecretCookie = (secretKey, key) => {
        const cookies = getParsedSecretCookies(secretKey);
        return cookies[key] || null;
    };

    return { getSecretCookie, setSecretCookies };
}

export default useSecretCookies;
