import { useEffect } from 'react';
import '../components/styles/PageNotFound.scss';
import { useNavigate, useLocation } from 'react-router-dom';

function PageNotFound() {

  const navigate = useNavigate();
  const location = useLocation();
  const fullUrl = `${window.location.origin}${location.pathname}${location.search}`;

  useEffect(() => {
    document.title = 'GoldenKingsz - 404';

    console.error(`GET ${fullUrl} 404 (Not Found)`);
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `url(/assets/images/top-5.webp)`,
  };

    return <div className="notFoundWrapper"  style={backgroundImageStyle}>
        
        <div className="notFoundWrapper__inner">
            <h1>Page Not Found</h1>
            <h2 className='large'>404</h2>
            <h2 className="h1">Error</h2>
        </div>

    </div>
}

export default PageNotFound;
