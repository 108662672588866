import React, {createContext, useEffect, useState} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useSecretCookies from "../customHooks/useSecretCookies";
import { logout } from "../utils/dataApi";
import { homepageGame } from "../utils/gamesList";
import checkUserAuth from "../utils/userData";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [cookies, setCookie] = useCookies(['is18', 'registration']);
  const [showAgePopup, setShowAgepopup] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [countryData, setCountryData] = useState({code: 'US', country: 'USA'});
  const { setSecretCookies } = useSecretCookies();
  const [clickedGameUrl, setClickedGameUrl] = useState(homepageGame);
  const [uAuth, setUAuth] = useState(false)

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    if (pathname === '/gb/' || pathname === '/gb') {
      window.location.href = "/gb/";
    } else if (pathname === '/top10/' || pathname === '/top10') {
      window.location.href = "/top10/";
    } else {
      navigate(0);
    }
  };

  const userLogout = async () => {
    const url = logout();
    await fetch(url)
      .then((response) => response.json())
      .then((result) => {
        setUAuth(false);
        navigate(0);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }
  const registrationCookie = getCookie('registration');  

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch(`https://ipapi.co/json/?key=1W62DydpgUZwtxR5l6BW8UPckBu2NLTTJLzOiuqtkwHgz4tNXz`);
            if (response.ok) {
                const data = await response.json();

                setSecretCookies('sc_geo', {
                    address_country: data.country_name,
                    address_city: data.city,
                    address_state: data.region,
                    address_zip: data.postal
                });

                const country_data = {
                    code: data.country_code,
                    country: data.country_name
                };
                setCountryData(country_data);

            } else {
                console.error('Response error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
      };
    fetchData();
  }, []);

    useEffect(() => {

        checkUserAuth()
            .then((result) => {
                // console.log(result);
                if (result.is_auth === true) {
                    setShowRegisterBtn(false);
                    setShowRegisterPopup(false);
                }
                // else if(result.is_auth === false) {
                //   if(pathname.split('/')[1] === "games"){
                //     setTimeout(() => {
                //       if(cookies.is18 === 'false') {
                //         setShowRegisterPopup(false);
                //       } else {
                //         setShowRegisterPopup(true);
                //       }
                //     }, 30000)
                //   }
                // }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

  return (
    <UserContext.Provider
      value={{
        showRegisterPopup,
        setShowRegisterPopup,
        showLoginPopup,
        setShowLoginPopup,
        showThanksPopup,
        setShowThanksPopup,
        cookies,
        setCookie,
        showRegisterBtn,
        setShowRegisterBtn,
        closeThanksPopup,
        showAgePopup,
        setShowAgepopup,
        showCookiePopup, 
        setShowCookiePopup,
        countryData,
        userLogout,
        registrationCookie,
        setClickedGameUrl,
        clickedGameUrl,
        setUAuth,
        uAuth
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
