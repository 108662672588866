import { useEffect } from 'react';

const CookiesPolicy = () => {

    useEffect(() => {
        document.title = 'GoldenKingz - Cookies Policy';
    }, []);

    return <div className="wrapper-wide legal-page">
            <div className="wrapper-wide__inner">
                <h1 className='title'>Cookie Policy</h1>
                <p>
                    Welcome to Golden Kingz. This Cookie Policy defines how cookies and similar tracking technologies are used on our website and platform. By using Golden Kingz, you consent to cookies as described below.  <br />
                    1. What Are Cookies? <br />
                    Cookies are small text files kept on your device when visiting a website. They help us enhance your user experience by remembering your preferences, settings, and actions on our platform. <br />
                    
                    2. Types of Cookies We Use: <br />
                    2.1. Essential Cookies: These cookies are required for the basic functionality of our platform. They enable you to navigate our site, use essential features, and access secure areas. Without these cookies, Golden Kingz may not function properly. <br />
                    2.2. Performance and Analytics Cookies: We use these cookies to collect information about how you use our platform. They help us understand user behavior and improve our services. These cookies do not collect personal information. <br />
                    2.3. Functional Cookies: These cookies enable us to recognize your preferences and sets, such as language preferences, so you have a more personalized experience during your visit. <br />
                    2.4. Advertising and Targeting Cookies: We do not use these cookies on Golden Kingz, as we are not involved in targeted advertising or share user data with third-party advertisers. <br />
                    
                    3. How to Manage Cookies <br />
                    You can manage your cookie choices by modifying your browser settings. Most web browsers permit you to delete or block cookies. However, please note that blocking essential cookies may affect the functionality of Golden Kingz. <br />

                    4. Updates to This Cookie Policy
                    We may update this Cookie Policy to recall changes in our practices or for legal and regulatory reasons. We will notify users of any significant updates through our platform. Please review this Cookie Policy periodically. <br/>
                    
                    5. Contact Us <br />
                    If you have any questions, troubles, or requests related to these Terms and Conditions or your use of Golden Kingz, please contact us. <br />
                    By using Golden Kingz, you agree to accept these Terms and Conditions and understand that they are designed to assure a safe and enjoyable entertainment experience. Thank you for being a part of Golden Kingz! <br />

                </p>
            </div>
        </div>
}

export default CookiesPolicy;