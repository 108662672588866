import './styles/HomeGrid.scss'
import './styles/LpGrid.scss'
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../customHooks/userHooks';
import { homepageGame, lpGame } from '../utils/gamesList';

const LpGrid = () => {

    const navigate = useNavigate();

    const { setShowRegisterPopup, setClickedGameUrl, clickedGameUrl } = useContext(UserContext);
    const { showRegisterBtn } = useUser();

    const handleClick = () => {
               
        setClickedGameUrl(lpGame);
        if(showRegisterBtn) {
            setShowRegisterPopup(true); 
        } else {
            navigate(`/games/${lpGame}`);
        }

    }

    return <>
    
        <div className="LpGrid gridWrapper">

            <div className="gridWrapper__grid">

                <div className="grid-col">

                    <h2 className='h1'>A Majestic Quest <br /> for Riches </h2>

                    <p>
                        This captivating game invites players to enter a kingdom in search of untold treasures. With its luxurious design and enticing 
                        features, "Gold King" promises a gaming experience fit for royalty. The intricate details and high-quality graphics create a 
                        visually stunning backdrop that enhances the overall gameplay.
                    </p>

                    <div className="spacer desktop-only_1000" style={{height: '2.5em'}}></div>

                    <div className="text-container__button">
                        <button className="default-btn" onClick={handleClick}> Play Now </button>
                    </div>

                </div>

                <div className="grid-col image">

                    <figure>
                        <img src="/assets/images/image-1.webp" alt="iron girl" />
                    </figure>

                </div>

            </div>

        </div>

    </>

}

export default LpGrid;