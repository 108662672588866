import React, {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import useSecretCookies from "../customHooks/useSecretCookies";

export default function SendThriveRequest() {
    const { pathname } = useLocation();
    const { setSecretCookies, getSecretCookie } = useSecretCookies();

    useEffect( () => {

        const subid = getSecretCookie('sc_subid', 'subid');

        if (subid == null || subid === '' || subid === 'na') {

            const browser_language = navigator.language || navigator.userLanguage || 'na';
            const browser_languages = navigator.languages || navigator.userLanguages || [];

            setSecretCookies('sc_lang', {
                browser_language: browser_language,
                browser_languages: browser_languages.join(';'),
            });

            fetch('/api/ad/click.php')
                .then((response) => {
                    const r = response.clone();
                    response.text().then(txt => {
                        fetch('/api/ad/log.php', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded"
                            },
                            body: JSON.stringify({response: txt, status: response.status})
                        });
                    })
                    if (!r.ok) {
                        throw new Error('Response error');
                    }
                    return r.json()
                })
                .then(async (rjson) => {
                    if (rjson.clickId === undefined || rjson.clickId === '') {
                        throw new Error('No clickId in response');
                    }

                    setSecretCookies('sc_subid', {subid: rjson.clickId});

                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [pathname]);

    return null;
}