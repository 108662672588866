import { useEffect } from 'react';

const Disclaimer = () => {

    useEffect(() => {
        document.title = 'GoldenKingz - Disclaimer';
      }, []);

    return <div className="wrapper-wide legal-page">
            <div className="wrapper-wide__inner">
                <h1 className='title'>Disclaimer</h1>
                <p>
                    Please read this disclaimer carefully before using the GoldenKingz website.
                </p>
                <p>
                Golden Kingz is intended for users who are 18 years of age or older. If you are under 18, you are not permitted to access or use our platform. By using Golden Kingz, you confirm that you are of legal age in your jurisdiction to engage in online gaming activities. <br />
                We are committed to promoting responsible gaming. Please remember that Golden Kingz is for entertainment purposes only. If you find that your gaming activities are becoming problematic or interfering with your daily life, we encourage you to seek help and consider self-exclusion or limitations on your gaming activities. <br />
                Golden Kingz does not involve real-money casino activity. We do not charge you to access or use our platform, and we do not offer the opportunity to win real prizes. All in-game purchases are optional and intended to enhance your entertainment experience. <br />
                Participating in Golden Kingz games and activities does not provide any opportunity to win in a real casino or receive any real rewards or prizes. <br />
                Golden Kingz may contain links to third-party websites or services for your convenience. Please be aware that we do not control these third-party sites or their content. Your interaction with these links is at your own discretion and risk. We recommend reviewing the terms and privacy policies of any third-party sites you visit. <br />

                </p>
            </div>
        </div>
}

export default Disclaimer;