import { useEffect } from 'react';
import '../../components/styles/Legal.scss';
import BackButton from '../../components/BackButton';

function Terms({ agree }) {

  useEffect(() => {
    document.title = 'GoldenKingz - Terms & Conditions';
  }, []);

  return (
    <div className="wrapper-wide legal-page">
      <div className="wrapper-wide__inner">
        {agree &&
          <BackButton />
        }
        <h1 className='title'>Terms & Conditions</h1>
        <p className='f-overlock'>
            Welcome to Golden Kingz, a free-to-play social casino created for your entertainment. These Terms and Conditions govern your use of our platform, and by accessing or using Golden Kingz, you agree to comply with and be bound by the following terms and conditions: <br />
            1. Entertainment and Free-to-Play <br />
            1.1. Entertainment Only: Golden Kingz is an online social casino created solely for entertainment. While you can enjoy various games and activities on our platform, we do not offer real-money gambling services, and you cannot win real money or prizes. <br />
            1.2. Free-to-Play: Access to Golden Kingz is entirely free, and no purchase is required to participate in our games or activities. We offer in-app purchases, but these are optional and intended to enhance your gaming experience. You are not required to make any purchases to enjoy our platform. <br />
            
            2. Ownership of Content <br />
            2.1. Golden Kingz Content: All content, including but not limited to games, graphics, design elements, text, and any other materials found on Golden Kingz, is the exclusive property of Golden Kingz or its licensors. You may not reproduce, distribute, or modify our content without our express written consent. <br />
            2.2. Trademarks: Any trademarks, logos, or service marks displayed on Golden Kingz are the property of Golden Kingz or third parties. Here, you may not use these items without the prior permission in written of the respective owner. <br />

            3. Third-Party Links and Content <br />
            3.1. Third-Party Links: Golden Kingz may contain links to third-party websites, services, or content for your convenience. We do not endorse or control these third-party websites or their content; your interaction with them is at your own risk. We recommend reviewing the terms and privacy policies of any third-party sites you visit.<br />
            3.2. User-Generated Content: Users of Golden Kingz may contribute user-generated content, such as comments or reviews. We do not endorse or take responsibility for the accuracy or reliability of user-generated content. Please use discretion and respect when interacting with such content.<br />
                        
            4. Limitation of Liability <br />
            4.1. No Warranty: Golden Kingz is provided "as is" without any warranties, whether expressed or implied. We do not guarantee our platform's accuracy, reliability, or availability, and we reserve the right to edit or discontinue our services at any time without notice.<br />
            4.2. Limitation of Liability: To the scope permitted by applicable legislation, Golden Kingz and its affiliates, officers, directors, employees, and agents shall not be responsible for any direct, indirect, incidental, particular, or consequential damages stemming from your use or inability to use our platform or from any information, content, or materials obtained through it.<br />


            5. Changes to Terms and Conditions <br />
            We may occasionally update these Terms and Conditions to reflect changes in our services or for legal and regulatory reasons. We will notify users of any significant updates through our platform. Please review these Terms and Conditions periodically.<br />

            
            6. Contact Us <br />
            If you have any questions, troubles, or requests related to these Terms and Conditions or your use of Golden Kingz, please contact us. <br />
            By using Golden Kingz, you agree to accept these Terms and Conditions and understand that they are designed to assure a safe and enjoyable entertainment experience. Thank you for being a part of Golden Kingz! <br />

        </p>
        <div className="spacer" style={{height: '2.5em'}}></div>
        {agree &&
          <BackButton />
        }
        </div>
    </div>
  );
}

export default Terms;
