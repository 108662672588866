import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { gamesList } from '../utils/gamesList';
import checkUserAuth from '../utils/userData';

import './styles/Play.css';
import BackButton from './BackButton';
import { useUser } from '../customHooks/userHooks';
import { UserContext } from '../context/UserContext';


function Play() {
  let navigate = useNavigate();
  const { userAuthenticated } = useUser();
  const { registrationCookie, setShowRegisterPopup, showRegisterBtn, cookies, uAuth } = useContext(UserContext);

  let { gameUrl } = useParams();
  const [url, setUrl] = useState();

  

  useEffect(() => {
    document.title = 'GoldenKingz - Play Game';

    const foundGame = gamesList.find(game => game.gameName === gameUrl);

    if((foundGame === undefined) || (registrationCookie === 'force' && showRegisterBtn === false)) {
      navigate('/page404/');
    } else {
      setUrl(foundGame.url);
    }

    checkUserAuth()
      .then((result) => {
        if(result.is_auth === false) {
          if(cookies.is18 === 'false') {
            setShowRegisterPopup(false);
          } else {
            setTimeout(() => {
              setShowRegisterPopup(true);
            }, 30000)
          }
        }
      })

  }, [setUrl, gameUrl, navigate]);

  return (
    <div className="text-page-body">
      <div className="content-cover main-container">

        <div className='wrapper-wide'>
          <BackButton />
        </div>
        
        
        <div className="game-cover">
          <iframe title="game" src={url} frameBorder="0"></iframe>
        </div>

      </div>
    </div >
  );
}

export default Play;
