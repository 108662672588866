import "./styles/Hero.scss";
import Hero from "./Hero";


const LpKnight = () => {
    
    const bgImg = '/assets/images/Clippathgroup.webp';
    const tLImg = '/assets/images/knight.webp';

    return <div className={`LpKnight`}>
            <Hero backgroundImage={bgImg} topLayerImage={tLImg} >
                <div className="spacer" style={{height: '8.222222222222222em'}}></div>

                <h2 className="h1">Discover the <br />Kingdom of Games</h2>

                <p>
                    Join us on this royal journey, where fortunes are won, dreams are realized, and the allure of the 
                    medieval world awaits. Whether you seek a momentary escape or a chance to reign supreme, the GoldenKingz is your gateway 
                    to a kingdom of excitement and possibility.Let the journey begin!
                </p>

                <div className="spacer" style={{height: '7.722222222222222em'}}></div>

            </Hero>
        </div>
}

export default LpKnight;

