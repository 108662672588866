import "./styles/Hero.scss";
import Hero from "./Hero";
import { Link } from 'react-router-dom';

const AboutUsHome = () => {
    
    const bgImg = '/assets/images/Asset121.webp';
    const tLImg = '/assets/images/King.webp';

    return <div className={`AboutUsHome`}>
            <Hero backgroundImage={bgImg} topLayerImage={tLImg} >
                <h2 className="h1">Embark on an extraordinary gaming adventure</h2>

                <p>
                    Our palace offers to enjoy a captivating game that will transport you to the enchanting world of knights and royalty. Try it now!
                </p>

                <div className="spacer" style={{height: '2.5em'}}></div>

                <div className="text-container__button">
                    <Link className="default-btn" to={'/about/'}>About Us</Link>
                </div>
            </Hero>
        </div>
}

export default AboutUsHome;

