import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/BackBtn.scss';
import { UserContext } from '../context/UserContext';
import { homepageGame } from '../utils/gamesList';



const BackButton = () => {
  const history = useNavigate();

  const { setClickedGameUrl } = useContext(UserContext)

  const goBack = () => {
    history(-1); // Go back to the previous page in history
    setClickedGameUrl(homepageGame)
  };

  return (
    <button onClick={goBack} className='GoBackBtn'>
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                <path d="M0.292711 6.79289C-0.0978127 7.18342 -0.0978127 7.81658 0.292711 8.20711L6.65667 14.5711C7.0472 14.9616 7.68036 14.9616 8.07089 14.5711C8.46141 14.1805 8.46141 13.5474 8.07089 13.1569L2.41403 7.5L8.07089 1.84315C8.46141 1.45262 8.46141 0.819457 8.07089 0.428932C7.68036 0.0384078 7.0472 0.0384078 6.65667 0.428932L0.292711 6.79289ZM19.0273 6.5H0.999819V8.5H19.0273V6.5Z" fill="#FEA23F"/>
            </svg>
        </span>
        Back
    </button>
  );
};

export default BackButton;
