import React, { useEffect } from 'react';

import AboutUs from '../components/AboutUs';

const About =  () => {

    useEffect(() => {
        document.title = 'Goldenkingz - About';
    }, []);

    return <div className='about-page'>
        <AboutUs />
    </div>

}

export default About;