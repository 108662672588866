import { useEffect } from 'react';

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = 'GoldenKingz - Privacy Policy';
      }, []);

    return <div className="wrapper-wide legal-page">
            <div className="wrapper-wide__inner">
                <h1 className='title f-overlock'>Privacy Policy</h1>
                <p className="f-overlock">
                    Welcome to Golden Kingz, a free-to-play social casino for entertainment. Golden Kingz is committed to ensuring the privacy and security 
                    of your personal information. This Privacy Policy outlines how we gather, use, and protect your info and data when you visit our website 
                    or use our services. You agree and consent to the practices laid out in this Privacy Policy by accessing or using Golden Kingz.
                    <br />
                    1. Information We Collect: <br />
                    1.1 Personal Information: We do not collect personal information from our users, such as names, addresses, or phone numbers. Golden Kingz is intended for users who are 18 years of age or older. <br /> 
                    1.2 Usage Data: We may compile non-personal information, including but not limited to your device's IP address, browser type, operating system, and usage patterns while interacting with our platform. <br />
                    1.3 Cookies: Golden Kingz uses cookies to enhance your experience. These small data files are stored on your device to track your preferences and usage. You can adapt your browser preferences to refuse cookies, but some features of our platform may not function correctly. <br />
                    1.4 Cookies and Tracking Technologies: GoldenKingz uses cookies, web beacons, and similar tracking technologies to enhance your experience and provide personalized content. These technologies allow us to remember your preferences, customize your gaming experience, analyze usage patterns, and deliver targeted advertisements. You can manage your cookie preferences through your browser settings, although disabling certain cookies may impact the functionality and usability of GoldenKingz. <br />
                    
                    2. How We Use Your Information: <br />
                    2.1 Improving Services: We use usage data to analyze and improve our services, making Golden Kingz more enjoyable for our users. <br />
                    2.2 Security: We use your data to maintain the security and integrity of our platform, ensuring fair play and preventing abuse. <br />
                    
                    3. Data Sharing: <br />
                    3.1 Third-Party Services: We may use third-party service providers to assist us in operating Golden Kingz. These service providers can access your data only to some perform tasks on our behalf and are obligated not to disclose or utilize it for any other aim. <br />
                    3.2 Legal Requirements: We may disclose your information when required by law or to protect our rights, privacy, safety, or property, as well as those of our users or the public.<br />

                    4. Security Measures <br />
                    We take your privacy seriously and employ industry-standard security means to protect your data from unauthorized access, disclosure, alteration, or destruction. <br />
                    
                    5. Children's Privacy <br />
                    Golden Kingz is intended for users who are 18 years of age or older. We do not knowingly collect any personal information from individuals under the age of 18. If you believe that a child has provided us with their personal data, please contact us immediately, and we will take measures to remove the information. <br />

                    6. Changes to this Privacy Policy <br />
                    We may alterate this Privacy Policy to show changes in our practices or for legal and regulatory reasons. We will notify users of any significant updates through our platform. Please review this Privacy Policy periodically. <br />

                    
                    7. Contact Us <br />
                    If you have any questions, problems, or requests regarding your info or this Privacy Policy, please contact us.
                    <br />
                    You agree to the terms explained in this Privacy Policy by using Golden Kingz. We value your trust and are committed to safeguarding your privacy while providing a fun and secure social casino experience.

                    
                </p>
            </div>
        </div>
}

export default PrivacyPolicy;